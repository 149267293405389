<template>
 <div id="page-container" class="pb-5">
  <div class="content-wrap">
   <PbxDashboard
    v-model:selector="periodSelector"
    @setSelector="setSelector = $event"
    @action="(isQueryParam = false), getDataFromElementForColumn()"
    @select-hostContext="getDataFromElementForColumn()"
    @all-selected="allSelected($event)"
    @button-selector="(...args) => getSelectedButton(...args)"
    @timeSelector="(isQueryParam = false), setTimeSelected($event)"
    :buttonSelected="this.buttonSelected"
    :extensions="extensions"
    :dataColumnChartQuarterHourlyCons="dataColumnChartQuarterHourlyCons"
    :dataColumnChartQuarterHourly="dataColumnChartQuarterHourly"
    :dataColumnChartHourly="dataColumnChartHourly"
    :dataColumnChartHourlyCons="dataColumnChartHourlyCons"
    :periodSelector="this.periodSelector"
    :timeSelected="this.timeSelected"
    :textMonth="this.textMonth"
    :urlType="urlType"
    :queryElement="queryElement"
   />
  </div>
  <div v-show="extensions.length === 0"><EmptyStateDashboard /></div>
 </div>
 <Loading
  v-model:active="isLoading"
  :can-cancel="true"
  :on-cancel="onCancel"
  :is-full-page="fullPage"
 />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import PbxDashboard from "../../components/PBX/PbxDashboard.vue";
import EmptyStateDashboard from "../../components/PBX/EmptyStateDashboard.vue";

export default {
 props: ["urlType"],
 components: {
  EmptyStateDashboard,
  Loading,
  PbxDashboard,
 },
 data() {
  return {
   queryElement: [],
   setSelector: undefined,
   isQueryParam: false,
   isLoading: false,
   fullPage: true,
   periodSelector: undefined,
   dataColumnChartQuarterHourly: [],
   dataColumnChartQuarterHourlyCons: [],
   dataColumnChartHourly: [],
   dataColumnChartHourlyCons: [],
   extensions: [],
   timeSelected: [],
   isAllSelected: "",
   buttonSelected: "month",
   textMonth: "",
   data: undefined,
  };
 },
 methods: {
  getButtonSelectedFromQueryPeriod() {
   let period = undefined;
   if (this.$route.query.period) {
    period = this.$route.query.period;
   }
   if (period) {
    if (period.length == 8) {
     this.buttonSelected = "day";
    } else if (period.length == 6) {
     this.buttonSelected = "month";
    } else {
     this.buttonSelected = "week";
    }
   }
  },
  async getPbxData(stringOfElements, period, type) {
   let context = undefined;
   let elements = undefined;
   let selectedPeriod = undefined;

   elements = stringOfElements;

   selectedPeriod = period;

   if (type === "queue") {
    context = "answered";
   } else {
    context = this.getUrlNameForContext(this.hostContext);
   }
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/pbxData/${
      this.hostName
     }/${context}?&elements=${elements}&days=${selectedPeriod}&type=${type}`,
     {
      headers: {
       Authorizations: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    return res.data;
   } catch (error) {
    const status = error.response.status;
    this.$emit("setLoading", false);
    this.errorHandling(error);
    if (error.response.status === 500) {
     const msg = this.$t("error500");
     this.$router.push({
      name: "Error",
      query: { status, msg },
     });
    }
   }
  },
  getUrlNameForContext(context) {
   let contextObj = {
    0: "answeredInCallQueues",
    1: "answeredInCallQueues",
    2: "answeredInCallQueues",
    3: "answered",
   };

   return contextObj[context];
  },
  setTimeSelected(event) {
   this.timeSelected = event;
  },
  allSelected(val) {
   this.isAllSelected = val;
  },
  getSelectedButton(...args) {
   this.buttonSelected = args[0];
   this.textMonth = args[1];
  },
  async getDataFromElementForColumn(isTimeSelected) {
   this.$store.dispatch("pbxElementType", this.urlType);

   let uniqueTimeLabel = ["0800", "1900"];

   let res = undefined;

   this.dataColumnChartQuarterHourly = [];
   this.dataColumnChartQuarterHourlyCons = [];
   this.dataColumnChartHourly = [];
   this.dataColumnChartHourlyCons = [];
   let period = this.currentMonthAndYearForPbx;
   let type = undefined;
   let arrayOfElements = [];
   let stringOfElements = "";
   this.extensions.length = 0;

   if (this.setSelector) {
    period = this.setSelector;
   }

   // if (!this.isQueryParam) {
   //   this.$router.replace({ tableElement: null, period: null });
   // }

   if (!this.setSelector && this.$route.query.period && this.isQueryParam) {
    period = this.$route.query.period;
    this.periodSelector = this.$route.query.period;
   }

   if (this.periodSelector) {
    period = this.periodSelector;
   }

   let pbxElements = undefined;

   if (!this.pbxElements && this.$route.query.tableElement && this.isQueryParam) {
    this.queryElement = new Array(this.$route.query.tableElement);
    pbxElements = new Array(this.$route.query.tableElement);
    this.$store.dispatch("pbxElements", pbxElements);
   } else {
    pbxElements = this.pbxElements;
   }

   if (pbxElements) {
    this.$emit("setLoading", true);
    pbxElements.forEach((element, index) => {
     let elementValue = element;
     if (typeof elementValue === "object") {
      for (let index = 0; index < elementValue.length; index++) {
       const element = elementValue[index];
       arrayOfElements.push(element);
      }
     } else {
      arrayOfElements.push(element);
     }
    });

    arrayOfElements = [...new Set(arrayOfElements)];

    stringOfElements = String(arrayOfElements[0]);
    for (let index = 1; index < arrayOfElements.length; index++) {
     stringOfElements = stringOfElements + "," + String(arrayOfElements[index]);
    }

    let obj = {};
    let objCons = {};
    let objHourly = {};
    let objHourlyCons = {};
    let dataObjAns = {};
    let dataObjAnsCons = {};
    let dataObjAnsHourly = {};
    let dataObjAnsHourlyCons = {};
    let dataObjUn = {};
    let dataObjUnCons = {};
    let dataObjUnHourly = {};
    let dataObjUnHourlyCons = {};

    type = this.urlType;

    if (isTimeSelected) {
     res = this.data;
    } else {
     res = await this.getPbxData(stringOfElements, period, type);
     this.data = res;
    }

    this.dataColumnChartQuarterHourly = [];
    this.dataColumnChartQuarterHourlyCons = [];
    this.dataColumnChartHourly = [];
    this.dataColumnChartHourlyCons = [];
    let keys = [];
    let keysHourly = [];
    let timeSelectedIsHour = false;
    let timeSelectedIsQuarter = false;
    let startTimeSelected = undefined;
    let endTimeSelected = undefined;

    if (this.timeSelected.length > 0) {
     if (this.timeSelected[0].slice(-2) === "00" && this.timeSelected[1].slice(-2) === "00") {
      timeSelectedIsHour = true;
     } else {
      timeSelectedIsQuarter = true;
     }
     startTimeSelected = this.timeSelected[0];
     endTimeSelected = this.timeSelected[1];
    }

    keys = Object.keys(res.sum_quarter_hourly);
    keysHourly = Object.keys(res.sum_hourly);
    console.log(
     "file: ExpertStatisticsAnswerUnansweredCalls.vue ~ line 248 ~ getDataFromElementForColumn ~ keysHourly",
     keysHourly
    );
    let timeLabelRange = [];
    for (let index = 0; index < keysHourly.length; index++) {
     const element = keysHourly[index];
     let time = element.split(":")[2].slice(-2);
     if (timeLabelRange.length) {
      if (!timeLabelRange[1]) {
       timeLabelRange.splice(1, 1, time);
      }
      if (parseInt(time) < parseInt(timeLabelRange[0])) {
       timeLabelRange.splice(0, 1, time);
      }
      if (parseInt(time) > parseInt(timeLabelRange[1])) {
       timeLabelRange.splice(1, 1, time);
      }
     } else {
      timeLabelRange.push(time);
     }
    }
    console.log(
     "file: ExpertStatisticsAnswerUnansweredCalls.vue ~ line 253 ~ getDataFromElementForColumn ~ timeLabelRange",
     timeLabelRange
    );
    let timeLabelStart = parseInt(timeLabelRange[0]) + parseInt(this.hostTimeZone) + "00";
    let timeLabelEnd = parseInt(timeLabelRange[1]) + 1 + parseInt(this.hostTimeZone) + "00";
    uniqueTimeLabel = [timeLabelStart, timeLabelEnd];
    console.log(
     "file: ExpertStatisticsAnswerUnansweredCalls.vue ~ line 277 ~ getDataFromElementForColumn ~ uniqueTimeLabel",
     uniqueTimeLabel
    );

    if (keys.length > 0 || keysHourly.length > 0) {
     let timeLabels = [];
     let timeLabelsCons = [];
     let timeLabelsHourly = [];
     let timeLabelsHourlyCons = [];

     if (timeSelectedIsHour || timeSelectedIsQuarter) {
      timeLabels = await this.getTimeLabelRangeStartAndEnd(startTimeSelected, endTimeSelected);
      timeLabelsCons = timeLabels;
      timeLabelsHourly = await this.getTimeLabelRangeHourlyStartAndEnd(
       startTimeSelected,
       endTimeSelected
      );
      timeLabelsHourlyCons = timeLabelsHourly;
     } else {
      timeLabels = await this.getTimeLabelRangeStartAndEnd(uniqueTimeLabel[0], uniqueTimeLabel[1]);
      timeLabelsCons = timeLabels;
      timeLabelsHourly = await this.getTimeLabelRangeHourlyStartAndEnd(
       uniqueTimeLabel[0],
       uniqueTimeLabel[1]
      );
      timeLabelsHourlyCons = timeLabelsHourly;
     }
     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      obj = {};
      objCons = {};
      objHourly = {};
      objHourlyCons = {};
      dataObjUnHourly = {};
      dataObjUnHourlyCons = {};
      dataObjUn = {};
      dataObjUnCons = {};
      dataObjAnsHourly = {};
      dataObjAnsHourlyCons = {};
      dataObjAns = {};
      dataObjAnsCons = {};
      dataObjAns["name"] = "answered";
      dataObjAns["data"] = [];
      dataObjAnsCons["name"] = "answered";
      dataObjAnsCons["data"] = [];
      dataObjUn["name"] = "unanswered";
      dataObjUn["data"] = [];
      dataObjUnCons["name"] = "unanswered";
      dataObjUnCons["data"] = [];
      obj["data"] = [];
      objCons["data"] = [];
      objHourly["data"] = [];
      objHourlyCons["data"] = [];
      obj["labels"] = [];
      objCons["labels"] = [];
      objHourly["labels"] = [];
      objHourlyCons["labels"] = [];

      let filledArrayDataAns = [];
      let filledArrayDataUns = [];
      let filledArrayDataAnsCons = [];
      let filledArrayDataUnsCons = [];
      let filledArrayDataAnsHourly = [];
      let filledArrayDataAnsHourlyCons = [];
      let filledArrayDataUnsHourly = [];
      let filledArrayDataUnsHourlyCons = [];

      filledArrayDataAns = new Array(timeLabels.length).fill(0);
      filledArrayDataAnsCons = new Array(timeLabelsCons.length).fill(0);
      filledArrayDataAnsHourly = new Array(timeLabelsHourly.length).fill(0);
      filledArrayDataUnsHourly = new Array(timeLabelsHourly.length).fill(0);
      filledArrayDataAnsHourlyCons = new Array(timeLabelsHourlyCons.length).fill(0);
      filledArrayDataUns = new Array(timeLabels.length).fill(0);
      filledArrayDataUnsCons = new Array(timeLabelsCons.length).fill(0);
      filledArrayDataUnsHourlyCons = new Array(timeLabelsHourlyCons.length).fill(0);

      keys.forEach((element, index) => {
       let splitElement = element.split(":");
       let elementName = "";
       let splitedHour = splitElement[2].slice(-4);
       elementName = res.map[arrayOfElements[arrIndex]];
       if (splitElement[1] == arrayOfElements[arrIndex]) {
        obj["name"] = splitElement[1] + " - " + elementName;
        if (splitElement[3] === "answered") {
         for (let index = 0; index < timeLabels.length; index++) {
          const elementTest = timeLabels[index];
          if (
           ("0" + String(parseInt(splitedHour.slice(0, 2)) + parseInt(this.hostTimeZone))).slice(
            -2
           ) +
            splitedHour.slice(-2) ===
           elementTest
          ) {
           filledArrayDataAns.splice(index, 1, res.sum_quarter_hourly[element]);
          }
         }
         dataObjAns["name"] = splitElement[3];
         dataObjAns["data"] = filledArrayDataAns;
        }

        if (splitElement[3] === "unanswered") {
         for (let index = 0; index < timeLabels.length; index++) {
          const elementTest = timeLabels[index];
          if (
           ("0" + String(parseInt(splitedHour.slice(0, 2)) + parseInt(this.hostTimeZone))).slice(
            -2
           ) +
            splitedHour.slice(-2) ===
           elementTest
          ) {
           filledArrayDataUns.splice(index, 1, res.sum_quarter_hourly[element]);
          }
         }
         dataObjUn["name"] = splitElement[3];
         dataObjUn["data"] = filledArrayDataUns;
        }
       } else if (splitElement[1] === "cons") {
        if (splitElement[3] === "answered") {
         for (let index = 0; index < timeLabelsCons.length; index++) {
          const elementTest = timeLabelsCons[index];
          if (
           ("0" + String(parseInt(splitedHour.slice(0, 2)) + parseInt(this.hostTimeZone))).slice(
            -2
           ) +
            splitedHour.slice(-2) ===
           elementTest
          ) {
           filledArrayDataAnsCons.splice(index, 1, res.sum_quarter_hourly[element]);
          }
         }
         dataObjAnsCons["name"] = splitElement[3];
         dataObjAnsCons["data"] = filledArrayDataAnsCons;
        }

        if (splitElement[3] === "unanswered") {
         for (let index = 0; index < timeLabelsCons.length; index++) {
          const elementTest = timeLabelsCons[index];
          if (
           ("0" + String(parseInt(splitedHour.slice(0, 2)) + parseInt(this.hostTimeZone))).slice(
            -2
           ) +
            splitedHour.slice(-2) ===
           elementTest
          ) {
           filledArrayDataUnsCons.splice(index, 1, res.sum_quarter_hourly[element]);
          }
         }
         dataObjUnCons["name"] = splitElement[3];
         dataObjUnCons["data"] = filledArrayDataUnsCons;
        }
       } else {
        // in case there is key but no data for element
        obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
       }
      });
      keysHourly.forEach((element, index) => {
       let splitElement = element.split(":");
       let splitedHour = splitElement[2].slice(-2);
       if (splitElement[1] == arrayOfElements[arrIndex]) {
        objHourly["name"] = splitElement[1];
        if (splitElement[3] === "answered") {
         for (let index = 0; index < timeLabelsHourly.length; index++) {
          const elementTest = timeLabelsHourly[index];
          if (
           ("0" + String(parseInt(splitedHour) + parseInt(this.hostTimeZone))).slice(-2) ===
           elementTest
          ) {
           filledArrayDataAnsHourly.splice(index, 1, res.sum_hourly[element]);
          }
         }
         dataObjAnsHourly["name"] = splitElement[3];
         dataObjAnsHourly["data"] = filledArrayDataAnsHourly;
        } else if (splitElement[3] === "unanswered") {
         for (let index = 0; index < timeLabelsHourly.length; index++) {
          const elementTest = timeLabelsHourly[index];
          if (
           ("0" + String(parseInt(splitedHour) + parseInt(this.hostTimeZone))).slice(-2) ===
           elementTest
          ) {
           filledArrayDataUnsHourly.splice(index, 1, res.sum_hourly[element]);
          }
         }
         dataObjUnHourly["name"] = splitElement[3];
         dataObjUnHourly["data"] = filledArrayDataUnsHourly;
        }
       } else if (splitElement[1] === "cons") {
        if (splitElement[3] === "answered") {
         for (let index = 0; index < timeLabelsHourlyCons.length; index++) {
          const elementTest = timeLabelsHourlyCons[index];
          if (
           ("0" + String(parseInt(splitedHour) + parseInt(this.hostTimeZone))).slice(-2) ===
           elementTest
          ) {
           filledArrayDataAnsHourlyCons.splice(index, 1, res.sum_hourly[element]);
          }
         }
         dataObjAnsHourlyCons["name"] = splitElement[3];
         dataObjAnsHourlyCons["data"] = filledArrayDataAnsHourlyCons;
         objHourlyCons["data"].splice(0, 1, dataObjAnsHourlyCons);
        } else if (splitElement[3] === "unanswered") {
         for (let index = 0; index < timeLabelsHourlyCons.length; index++) {
          const elementTest = timeLabelsHourlyCons[index];
          if (
           ("0" + String(parseInt(splitedHour) + parseInt(this.hostTimeZone))).slice(-2) ===
           elementTest
          ) {
           filledArrayDataUnsHourlyCons.splice(index, 1, res.sum_hourly[element]);
          }
         }
         dataObjUnHourlyCons["name"] = splitElement[3];
         dataObjUnHourlyCons["data"] = filledArrayDataUnsHourlyCons;
         objHourlyCons["data"].splice(1, 1, dataObjUnHourlyCons);
        }
       } else {
        // ! in case there is key but no data for element
        let elementName = "";
        elementName = res.map[arrayOfElements[arrIndex]];
        objHourly["name"] = arrayOfElements[arrIndex] + " - " + elementName;
       }
      });
      obj["labels"] = timeLabels;
      objHourly["labels"] = timeLabelsHourly;
      objCons["labels"] = timeLabelsCons;
      objHourlyCons["labels"] = timeLabelsHourlyCons;

      obj["data"].splice(0, 1, dataObjAns);
      obj["data"].splice(1, 1, dataObjUn);

      objHourly["data"].splice(0, 1, dataObjAnsHourly);
      objHourly["data"].splice(1, 1, dataObjUnHourly);

      objCons["data"].splice(0, 1, dataObjAnsCons);
      objCons["data"].splice(1, 1, dataObjUnCons);

      this.extensions.splice(arrIndex, 1, obj["name"]);
      this.dataColumnChartQuarterHourly.splice(arrIndex, 1, obj);
      this.dataColumnChartHourly.splice(arrIndex, 1, objHourly);
      this.dataColumnChartQuarterHourlyCons.splice(0, 1, objCons);
      this.dataColumnChartHourlyCons.splice(0, 1, objHourlyCons);
     }
    } else {
     for (let arrIndex = 0; arrIndex < arrayOfElements.length; arrIndex++) {
      obj = {};
      objHourly = {};
      objCons = {};
      let elementName = "";
      elementName = res.map[arrayOfElements[arrIndex]];

      objHourly["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      obj["name"] = arrayOfElements[arrIndex] + " - " + elementName;
      objCons["name"] = "cons";
      objHourlyCons["name"] = "cons";
      obj["labels"] = [];
      objHourly["labels"] = [];
      objCons["labels"] = [];
      objHourlyCons["labels"] = [];
      obj["labels"] = [];
      obj["data"] = [];

      objHourly["data"] = objCons["data"] = obj["data"];

      this.extensions.splice(arrIndex, 1, obj["name"]);
      this.dataColumnChartQuarterHourly.splice(arrIndex, 1, obj);
      this.dataColumnChartHourly.splice(arrIndex, 1, objHourly);
      this.dataColumnChartQuarterHourlyCons.splice(0, 1, objCons);
      this.dataColumnChartHourlyCons.splice(0, 1, objHourlyCons);
     }
    }
   }
   this.$emit("setLoading", false);
  },
  async getTimeLabelRange(arr) {
   let sortedArray = arr.sort((a, b) => {
    return a - b;
   });
   let start = ("0" + String(sortedArray[0])).slic;
   let end = ("0" + String(sortedArray[arr.length - 1])).slic;
   try {
    const res = await axios.post(
     `${this.$cookie.getCookie("API")}/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`
    );
    return res.data;
   } catch (error) {
    console.log(error);
    this.errorHandling(error);
   }
  },
  async getTimeLabelRangeStartAndEnd(start, end) {
   try {
    const res = await axios.post(
     `${this.$cookie.getCookie("API")}/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`
    );
    return res.data;
   } catch (error) {
    console.log(error);
    this.errorHandling(error);
   }
  },
  async getTimeLabelRangeHourly(arr) {
   let hourlyRange = [];
   let sortedArray = arr.sort((a, b) => {
    return a - b;
   });
   let end = "";
   let start = ("0" + String(sortedArray[0]) + "00").slice(0, 4);
   if (String(sortedArray[arr.length - 1]).length > 1) {
    end = (String(sortedArray[arr.length - 1]) + "00").slice(0, 4);
   } else {
    end = ("0" + String(sortedArray[arr.length - 1]) + "00").slice(0, 4);
   }
   try {
    const res = await axios.post(
     `${this.$cookie.getCookie("API")}/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`
    );
    for (let index = 0; index < res.data.length; index++) {
     const element = res.data[index];
     if (element.slice(-2) == "00") {
      hourlyRange.push(element.slice(0, 2));
     }
    }
    return hourlyRange;
   } catch (error) {
    console.log(error);
    this.errorHandling(error);
   }
  },
  async getTimeLabelRangeHourlyStartAndEnd(start, end) {
   let hourlyRange = [];
   try {
    const res = await axios.post(
     `${this.$cookie.getCookie("API")}/api/v1/helpers/time/timeLabels?start=${start}&end=${end}`
    );
    for (let index = 0; index < res.data.length; index++) {
     const element = res.data[index];
     if (element.slice(-2) == "00") {
      hourlyRange.push(element.slice(0, 2));
     }
    }
    return hourlyRange;
   } catch (error) {
    console.log(error);
    this.errorHandling(error);
   }
  },
 },
 mounted() {
  if (!this.periodSelector) {
   this.periodSelector = this.currentMonthAndYearForPbx;
  }
  if (this.pbxPeriodSelected) this.periodSelector = this.pbxPeriodSelected;

  if (this.$route.query.tableElement && this.$route.query.period) {
   this.isQueryParam = true;
  }

  if (this.pbxElementType !== this.urlType) this.$store.dispatch("pbxElements", null);

  this.getButtonSelectedFromQueryPeriod();
 },
 computed: {
  ...mapGetters([
   "account",
   "pbxElements",
   "pbxTimeSelected",
   "hostName",
   "hostContext",
   "hostTimeZone",
   "pbxElementType",
   "pbxPeriodSelected",
  ]),

  currentMonthAndYearForPbx() {
   let options = { month: "short" };
   let month = String("0" + (new Date().getMonth() + 1)).slice(-2);
   let formattedMonth = new Intl.DateTimeFormat("fr-FR", options).format(month).slice(0, -2);
   this.textMonth = formattedMonth;
   let year = new Date().getFullYear();
   return String(year) + String(month);
  },
 },
 watch: {
  periodSelector: function (val) {
   // this.timeSelected.length = 0;
   this.getDataFromElementForColumn();
  },
  timeSelected: function (val) {
   this.getDataFromElementForColumn(true);
  },
 },
};
</script>

<style>
#page-container {
 position: relative;
 min-height: 97%;
}
#content-wrap {
 padding-bottom: 2rem; /* Footer height */
}
#footer {
 position: absolute;
 bottom: 0;
 width: 100%;
 height: 2.5rem; /* Footer height */
}
</style>
